body {
    background-image:url('/build/images/stock/caduceus.gif');
    background-position:bottom center;
    background-size:25%;
    //background-size:90% 90%;
    background-repeat:no-repeat;
    background-attachment:fixed;
}

div.content
{
    background-color:rgba(255, 255, 255, 0.77);
    //background-image:url('/build/images/lamens/sec_vector_grey.png');
    background-position:95% 95%;
    background-repeat:no-repeat;
    background-size:25%;
    padding:18px;
    -webkit-box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.48);
    -moz-box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.48);
    box-shadow: 0px 0px 18px 8px rgba(0,0,0,0.48);
    min-height:600px;
    background-width:100%;
    background-height:100%;
    margin-bottom:32px;
}

dd
{
    margin-bottom:0.5em;
}

footer
{
    bottom:0px;
    margin-top:26px;
}

copyright
{
    font-size:13px;
}

p {
    font-size:15.5px;
}

.panel-event {
    text-overflow:ellipsis;
}

/* Small devices (tablets, 768px and up) */
@media (min-width: $screen-sm-min) {
    .panel-event {
        min-height:225px;
        max-height:225px;
        padding-top:0px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: $screen-md-min) {
    .panel-event {
        min-height:225px;
        max-height:225px;
        padding-top:0px;
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: $screen-lg-min) {
    .panel-event {
        min-height:225px;
        max-height:225px;
        padding-top:0px;
    }
}