#scrollbar1 {
    width: 280; clear: both; margin: 20px 0 10px; display: block; font-size: 15px; font-family: 'serifFontFamily';position: absolute;right: 75px;top: 110px;text-align: center; }
#scrollbar1 .viewport { width: 230px; height: 400px; overflow: hidden; position: absolute;right: 12px; }
#scrollbar1 .overview { list-style: none; position: absolute; left: 0; top: 0; }
#scrollbar1 .thumb .end,
#scrollbar1 .thumb { border-radius: 12px;
    background-color: #7D4100; }
#scrollbar1 .scrollbar { 
    position: relative; float: right; width: 10px; }
#scrollbar1 .track { border-radius: 12px;background-color:transparent; height: 100%; width:10px; position: relative; padding: 1px;padding-top: 1px; }
#scrollbar1 .thumb { height: 10px; width: 8px; cursor: pointer; overflow: hidden; position: absolute; top: 1px; }
#scrollbar1 .thumb .end { overflow: hidden; height: 12px; width: 10px;border-radius: 12px; }
#scrollbar1 .disable{ display: none; }